/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0px;
  padding: 0px;
}
ul {
  padding: 0 !important;
  margin: 0 !important;
}

.login-form-box {
  box-shadow: 0 0 3px 3px #33333330;
  border-radius: 7px;
  padding: 30px 30px !important;
}
.login-form-box button {
  background-color: #85bb65;
  border: unset;
  padding: 10px 30px;
  margin-left: auto;
  display: flex;
}

/* .main-header.py-3 {
  background-color: #85bb65;
  color: #fff;
} */
/* .login-form {
  margin: 100px 0 0 0;
} */

.nav-box ul a li {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}
.nav-box ul a {
  text-decoration: navajowhite;
}

.card {
  background-color: #fff;
  border: none;
  box-shadow: 0 0 3px 3px #33333324;
  padding: 12px 20px;
  margin-bottom: 20px;
}
.card h5 {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}
.card p {
  font-size: 15px;
  padding: 0 0px 0 0;
  text-align: left;
  margin-bottom: 0 !important;
}

.map-card button {
  background-color: unset;
  border: unset;
  padding: 0;
  margin: 0 0 0 20px;
}
.pagination {
  padding: 20px 0 !important;
}
.pagination .page-link {
  padding: 7px 11px;
}
.main-card-box {
  margin: 20px 0 0 0;
  height: 520px;
  overflow: scroll;
}
.map-right-box {
  background-color: #eaeaea;
}
.map-header button {
  background-color: #85bb65;
  padding: 10px 13px;
  border: unset;
  margin: 0 4px;
  border-radius: 4px;
}

.map-header button img {
  width: 16px;
  /* margin: -4px 0 0 0; */
}
.page-link {
  color: #85bb65 !important;
  font-weight: bold;
  box-shadow: unset !important;
}
.page-link:focus {
  background-color: #85bb65 !important;
  color: #333 !important;
}
.form-select {
  box-shadow: unset !important;
}
.form-control {
  box-shadow: unset !important;
}

.marker-text {
  background-color: #fff;
  border-radius: 10px;
  border-width: 1px;
  width: 360px;
  text-align: left;
  padding: 10px 10px;
  position: absolute;
  bottom: 43px;
  z-index: 9;
  left: 0;
  transform: translate(-45%, 0);
  box-shadow: 0 0 3px 3px #33333352;
}
.pin-map-box {
  position: relative;
}
.login-form input {
  padding: 10px 0 10px 30px !important;
  border-radius: 6px;
  margin: 0 0 12px 0;
  display: block;
}

.icon-box {
  position: absolute;
  top: 47%;
  transform: translate(0, -50%);
  left: 9px;
}
input {
  padding: 0 0 0 40px !important;
}

.logo img {
  width: 90px;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
}

.login-form button {
  background-color: #85bb65;
  color: #000 !important;
  font-weight: 700 !important;
}

.login-bottom ul li a {
  color: #85bb65;
  text-decoration: unset;
  margin: 0 11px;
  border: 2px solid #333 !important;
  padding: 1px 6px;
  border-top: unset !important;
  border-bottom: unset !important;
  border-radius: 4px;
}
.login-bottom ul {
  justify-content: center;
}
.login-bottom {
  margin: 23px 0;
}

.login-bottom ul li a {
  color: #85bb65;
  text-decoration: unset;
  margin: 0 11px;
  border: 2px solid #333 !important;
  padding: 1px 6px;
  border-top: unset !important;
  border-bottom: unset !important;
  border-radius: 4px;
}
.login-bottom ul {
  justify-content: center;
}
.login-bottom {
  margin: 23px 0;
}
.signup-btn-box span {
  color: #000;
  font-size: 16px;
}
.signup-btn-box span a {
  background-color: #85bb65;
  color: #000;
  text-decoration: unset;
  padding: 9px 15px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
}

.dropdown button {
  background-color: unset;
  color: #333;
  display: flex;
  align-items: center;
  border: unset;
}
.dropdown button img {
  background-color: #ccc;
  padding: 10px 10px;
  width: 40px;
  height: 40px;
  border-radius: 70px;
  margin: 0 10px 0px 0px;
}
.dropdown button span {
  display: block;
  margin: 0 10px 0 0px;
}
.navbar-nav li {
  font-size: 17px;
  margin: 0 14px;
}

.main-header.py-3 {
  padding: 0px !important;
  box-shadow: 0 0 3px 3px #33333338;
  margin: 0 0 60px 0;
}
.navbar-brand img {
  width: 90px;
}
.form-control {
  padding: 0.375rem 0.75rem !important;
}
.btn.btn-primary:hover {
  background-color: #85bb65 !important;
}

.btn.btn-primary:hover {
  background-color: #85bb65;
}
.purchase-btn {
  width: 90%;
  margin: 20px auto 30px auto;
  padding: 10px;
  background-color: #85bb65;
  border: unset;
  color: #fff;
  text-decoration: unset;
  text-align: center;
}
.purchase-btn:hover {
  color: #fff !important;
}
.modal-body {
  font-size: 17px;
  text-align: center;
}

.map-card img {
  width: 20px;
  height: 20px;
}
.map-card {
  align-items: center;
  margin: 12px 0 0 0;
}

.accordion-button {
  width: 100%;
}
.accordion-button.collapsed {
  padding: 10px 0 15px 0 !important;
  box-shadow: unset;
}
.accordion-header {
  border: unset;
}

.accordion-button:focus {
  border: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
  padding: 10px 0 15px !important;
}
.accordion-button:not(.collapsed) {
  background-color: unset !important;
  border: unset !important;
  color: unset !important;
  box-shadow: unset !important;
  padding: 11px 0 15px;
}
.accordion-body {
  padding: 0 !important;
}
.accordion-item {
  border: unset !important;
  text-align: left;
}

.accordion-body a {
  font-size: 15px;
}
.accordion-body p {
  color: #333;
  margin: 4px 0;
}
.logout-btn {
  background-color: unset;
  text-align: left;
  margin: 0 0 0 4px;
}

.logout-btn.btn.btn-primary:hover {
  background-color: unset !important;
  color: #000 !important;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: unset !important;
  color: unset !important;
  border-color: unset;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: unset !important;
  color: unset !important;
}

.purchase-detail-box {
  text-align: left;
  box-shadow: 0 0 3px 3px #3333332b;
  padding: 40px 40px !important;
  border-radius: 10px !important;
}
.purchase-detail-box button {
  color: #fff;
  padding: 10px 50px;
  width: 100%;
  margin: 20px 0 0 0;
}

.purchase-heading p {
  font-size: 17px;
  margin: 15px 0;
}
.purchase-heading p strong {
  font-weight: 600;
}
.purchase-detail-box input {
  padding: 10px 10px !important;
}
.purchase-heading h1 {
  font-size: 30px;
  text-align: center;
  margin: 0px 0 40px 0;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
td {
  text-align: center;
}

td {
  text-align: center;
}
.side-bar {
  box-shadow: 0 0 3px 3px #3333333d;
  border-radius: 10px;
  padding: 0px 0;
  margin: 0 0 0 0px;
  height: 300px;
}
.side-bar button {
  width: 100%;
  text-align: left;
  display: flex;
  margin: 12px 0;
  padding: 15px 15px;
  color: #fdff;
  font-weight: bold;
}
.table-box {
  box-shadow: 0 0 3px 3px #3333333d;
  border-radius: 10px;
  margin: 0 0 0 10px;
}
td {
  padding: 16px 0;
}
th {
  padding: 16px 0;
}

.loader-container {
  width: 100px;
  height: 40px;
}
.modal-title {
  font-size: 20px !important;
}

.pin-map-box {
}
.marker-text {
}
.pin-map-box {
}
.marker-text h6 {
  font-size: 21px;
}
.marker-text h5 {
  font-size: 23px;
  font-weight: 300;
}
.marker-text p {
  font-size: 14px;
  color: #7f878e;
  margin: 0 0 13px 0;
}
.marker-text a {
  font-size: 16px;
  margin: 0 0 10px 0;
  display: block;
  color: #7f878e;
  text-decoration: unset;
}
.marker-text strong {
  color: #000 !important;
  margin: 0 5px 0 0px;
  font-size: 15px;
  margin: 0;
}
.marker-text button {
  background-color: #85bb65;
  border: unset !important;
  width: 100%;
  margin: 10px 0 10px 0;
}
.animate-spin {
  text-align: center;
  margin: 0px auto;
  width: 20px;
  height: 20px;
}

.purchase-detail-box h2 {
  margin: 0 0 19px 0;
  font-size: 26px;
}
.login-form {
  height: 100vh;
}
.login-bottom ul li {
  list-style-type: none;
}
.login-form .shadow {
  border-radius: 12px;
}
.login-form input:focus-visible {
  border: 1px solid #ccc !important;
  outline: unset !important;
}

.title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container {
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.upgrade-btn {
  background-color: #85bb65;
  color: #000 !important;
  font-weight: 700 !important;
  border-radius: 7px;
}
.logo-2 img {
  width: 170px !important;
}
.paginationNote{
  font-size: 12px;
  margin-top: -15px;
  text-align: left;
  background: white;
  padding: 5px;
  border-radius: 7px;
}
.loaderContainer{
  position: absolute;
  background-color: #03030330;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.textareaReport{
  padding: 10px;
  border: solid;
  border-color: lightgray;
  border-radius: 4px;
}